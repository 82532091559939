import { Accordion, useAccordion, Button, Card, Select, IconAngleDown, IconAngleUp, Link } from 'hds-react';
import ExternalLink from "../../../../src/components/ExternalLink";
import Playground from "../../../../src/components/Playground";
import TabsLayout from "../../../../src/docs/courses/portfolio-101/tabs.mdx";
import * as React from 'react';
export default {
  Accordion,
  useAccordion,
  Button,
  Card,
  Select,
  IconAngleDown,
  IconAngleUp,
  Link,
  ExternalLink,
  Playground,
  TabsLayout,
  React
};